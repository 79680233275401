/* Base styles */
.marion-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    justify-content: center;
    padding: 16px;
  }
  
  .marion-card {
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
  }
  
  .marion-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .marion-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .marion-card h3 {
    margin: 8px;
    font-size: 1.2em;
  }
  
  .marion-card p {
    margin: 8px;
    color: #666;
  }
  
  .bold-underline {
    font-weight: bold;
    text-decoration: underline;
    font-size: 2em;
    line-height: 1.5;
    text-align: center;
  }
  .marion-header-image {
    /* border: 2px solid red; */
    height: auto;
    max-width: 100%;

   
  }
  
  
  .center-bold {
    font-weight: bold;
    text-align: center;
  }
  
  /* Section container */
  .section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .text-container {
    margin-bottom: 20px;
  }
  .text-container {
    text-align: center; /* Center align text and image */
    margin: 0 auto; /* Center container */
  }
  
  .bold-underline {
    font-weight: bold;
    text-decoration: underline;
    margin-bottom: 16px; /* Add space below the text */
  }
  
  .marion-header-image {
    display: block; /* Ensures image is block-level and takes up full width available */
    margin: 0 auto; /* Center the image horizontally */
    max-width: 100%; /* Make sure image scales down if necessary */
  }
  
  
  .images-container {
    width: 100%;
  }
  
  .reserve-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  /* .reserve-button:hover {
    background-color: #0056b3;
  }
   */



  /* Media queries for responsiveness */
  @media (min-width: 600px) {
    .section-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  
    .text-container {
      flex: 1;
      padding-right: 16px;
    }
  
    .images-container {
      flex: 0 0 70%;
      max-width: 70%;
    }
  }
  
  @media (max-width: 599px) {
    .bold-underline {
      font-size: 1.5em;
    }
  
    .marion-card {
      width: 90%; /* Adjust width for smaller screens */
      margin-bottom: 16px; /* Add space between cards */
    }
  
    .marion-image {
      height: 200px; /* Adjust height for smaller screens */
    }
  }
  
  @media (min-width: 1024px) {
    .bold-underline {
      font-size: 3em;
    }
  }
  
  /* Slick arrow customizations */
  .slick-prev:before,
  .slick-next:before {
    color: black;
  }
  
  .marion-card {
    width: 100%;
    height: 400px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .marion-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .marion-card p {
    margin-top: 8px;
    font-size: 1.2em;
    text-align: center;
  }
  
  /* Ensure items are in a single row */
  .marion-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
    padding: 16px;
  }
  
  .marion-card {
    width: 100%;
    height: 400px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
  }
  
  .marion-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .marion-card.expanded {
    width: 100%;
    height: auto;
    transform: scale(1.2);
  }
  
  .marion-image 

  {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .marion-card h3 {
    margin: 8px;
    font-size: 1.2em;
  }
  
  .marion-card p {
    margin: 8px;
    color: #666;
    text-align: center;
  }
  
  /* Customize arrow colors and positions */
  .slick-prev:before,
  .slick-next:before {
    color: black;
    font-size: 24px;
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  
  /* Style dots */
  .slick-dots li button:before {
    color: black;
  }
  
  /* Style active dot */
  .slick-dots .slick-active button:before {
    color: red;
  }
  
  .images-container {
    width: 100%;
    position: relative;
  }
  
  .section-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  