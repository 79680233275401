/* Base styles */
.penthouse-container {
    display: flex;
    flex-wrap: nowrap; /* Ensure items are in a single row */
    overflow: hidden; /* Ensure no overflow */
    padding: 16px;
    gap: 16px;
    justify-content: center;
  }
  
  .penthouse-card {
    width: 100%;
    height: 400px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
    flex-shrink: 0; /* Prevent shrinking */
  }
  
  .penthouse-card:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .penthouse-card.expanded {
    width: 100%;
    height: auto;
    transform: scale(1.2);
  }
  
  .penthouse-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .penthouse-card p {
    margin: 8px;
    color: #666;
    font-size: 1.2em;
    text-align: center;
  }
  .reserve-button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 1em;
    color: white;
    background-color: black;
    border: none;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  



  /* Slick arrow customizations */
  .slick-prev:before,
  .slick-next:before {
    color: black;
    font-size: 24px;
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  
  /* Style dots */
  .slick-dots li button:before {
    color: black;
  }
  
  /* Style active dot */
  .slick-dots .slick-active button:before {
    color: red;
  }
  
  /* Media queries for responsiveness */
  @media (min-width: 600px) {
    .penthouse-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  
    .text-container {
      flex: 1;
      padding-right: 16px;
    }
  
    .images-container {
      flex: 0 0 70%;
      max-width: 70%;
    }
  }
  
  @media (max-width: 599px) {
    .penthouse-card {
      width: auto; /* Adjust width for smaller screens */
      margin-bottom: 16px; /* Add space between cards */
    }
  
    .penthouse-image {
      height: 400px; /* Adjust height for smaller screens */
      
    }
  
    .penthouse-container {
      padding: 8px; /* Reduce padding to save space */
    }
  }
  
  @media (min-width: 1024px) {
    .penthouse-card {
      font-size: 1.5em;
      
    }
  }
  
  