

* {
    box-sizing: border-box;
}

.item::after {
    content: "";
    display: table;
    clear: both;
}

.item {
    float: left;
    width: 30%; /* Adjust this as needed */
    padding: 10px;
    transition: transform 0.6s ease;
}

.image-container {
    width: 100%; /* This ensures the image container takes the full width of the item */
    height: 400px; /* Increase the height to make the container bigger */
    overflow: hidden; /* Ensure the image doesn't overflow */
}

.item img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Cover the container without distortion */
    border-radius: 5px;
}

.item h2 {
    margin: 10px 0;
    color: black;
}

.item-avalability {
    display: flex;
    gap: 20px;
}

.item-new {
    color: #374151;
    font-size: 18px;
    font-weight: 600;
}

.item-available-not {
    color: #8c8c8c;
    font-size: 18px;
    font-weight: 500;
    text-decoration: line-through;
}

.item:hover {
    transform: scale(1.05);
}