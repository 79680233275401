/* .hero-image {
    width: 50px;
    height: 30px;
} */
/* Base styles */
.hero-image {
  width: 100%;
  height: auto;
}

.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 16px; /* Reduced margin */
  padding: 16px; /* Apply padding */
}

.bold-underline {
    font-size: 200%;
    text-align: center; /* Center text */
  padding: 8px 0; /* Vertical padding */
}

.text-container {
  margin-bottom: 12px; /* Reduced margin */
  padding: 8px; /* Apply padding */
}

.images-container {
  width: 100%;
  padding: 8px; /* Apply padding */

}
/* Mansions.module.css or style.css */


 

.center-bold {
  font-weight: bold;
  text-align: center;
  font-size: 1.2em; /* Reduced font size */
  padding: 8px 0; /* Vertical padding */
}

@media (min-width: 600px) {
  .section-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .text-container {
    flex: 1;
    padding-right: 16px; /* Adjust padding */
    padding-bottom: 8px; /* Adjust bottom padding */
  }

  .images-container {
    flex: 0 0 70%;
    max-width: 70%;
  }
}

@media (max-width: 599px) {
  .bold-underline {
    font-size: 1.2em; /* Adjusted font size */
    text-align: center;
    padding: 4px 0; /* Reduced padding */
  }

  .center-bold {
    font-size: 1em; /* Adjusted font size */
    padding: 4px 0; /* Reduced padding */
  }
}
