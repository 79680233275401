

.offers img{
    width: 100%;
   max-height: 500px; 
   height: auto;
}

.offers {
    width: 90%; /* Flexible width */
    max-width: 850px; /* Prevent stretching too much */
    height: auto; /* Allow height to adjust based on content */
    display: flex;
    align-items: center;
    margin: 0 auto; /* Center the section */
    padding: 10px; /* Adjusted padding */
    background: linear-gradient(180deg, #fde1ff 0%, #e1ffea22 60%);
}

.offers-left, .offers-right {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.offers-right {
    padding-top: 20px; 
    width: 100%;
    max-height: 600px; 
    height: auto;
    padding-bottom: 90px;
    
}

/* For Tablets and Small Laptops */
@media (max-width: 1024px) {
    .offers-left input,
    .offers-left label {
        font-size: 1rem; /* Slightly smaller font size */
        padding: 8px; /* Adjust padding */
    }
}

/* For Smartphones */
@media (max-width: 768px) {
    .offers-left input,
    .offers-left label {
        font-size: 0.9rem; /* Smaller font size for mobile */
        padding: 6px; /* Reduce padding */
    }

    .offers {
        flex-direction: column; /* Stack fields vertically */
        padding: 10px; /* Adjust padding */
    }

    .offers-left input[type="email"] {
        margin-top: 10px; /* Adjust spacing */
    }
}

/* For Small Devices (Smartwatch) */
@media (max-width: 320px) {
    .offers-left input,
    .offers-left label {
        font-size: 0.7rem; /* Even smaller font size */
        padding: 4px; /* Minimal padding */
    }

    .offers {
        padding: 5px; /* Minimal padding */
    }

    .offers-left input[type="email"] {
        margin-top: 5px; /* Adjust spacing */
    }
}
