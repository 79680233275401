/* Base styles for slider arrows */








.slick-prev,
.slick-next {
  font-size: 1.5rem; /* Use relative units for better scaling */
  color: #333; /* Arrow color */
  background: rgba(255, 255, 255, 0.8); /* Background color */
  border-radius: 50%; /* Round shape */
  width: 40px; /* Width of the arrow button */
  height: 40px; /* Height of the arrow button */
  line-height: 40px; /* Center the arrow vertically */
  text-align: center; /* Center the arrow horizontally */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional shadow */
  z-index: 1; /* Ensure the arrow is above the slider */
  position: absolute; /* Positioning to place arrows correctly */
}

.slick-prev {
  left: 10px; /* Position from the left */
}

.slick-next {
  right: 10px; /* Position from the right */
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick'; /* Ensure the icon font is loaded */
  font-size: 1.5rem; /* Use relative units for better scaling */
}

.slick-prev:before {
  content: '←'; /* Custom content for the previous arrow */
}

.slick-next:before {
  content: '→'; /* Custom content for the next arrow */
}

/* Media Queries for responsive adjustments */
@media (max-width: 1200px) {
  .slick-prev,
  .slick-next {
    width: 35px; /* Adjust size for medium screens */
    height: 35px;
    font-size: 1.2rem; /* Adjust font size */
  }
}

@media (max-width: 992px) {
  .slick-prev,
  .slick-next {
    width: 30px; /* Adjust size for smaller screens */
    height: 30px;
    font-size: 1rem; /* Adjust font size */
  }
}

@media (max-width: 768px) {
  .slick-prev,
  .slick-next {
    width: 25px; /* Adjust size for even smaller screens */
    height: 25px;
    font-size: 0.8rem; /* Adjust font size */
  }
}

@media (max-width: 576px) {
  .slick-prev,
  .slick-next {
    width: 20px; /* Adjust size for very small screens */
    height: 20px;
    font-size: 0.6rem; /* Adjust font size */
  }
}
