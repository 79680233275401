.hero{
   height: 100vh;
   background: linear-gradient(180deg, #fde1ff, #e1ffea22 60%);
    display: flex;
    margin-bottom: 50px;
  
}
.hero-left{
    /* flex: 1; */
    /* display: flex; */
    flex-direction: column;
    /* justify-content: center; */
    gap: 20px;
    padding-left: 180px;
    line-height: 1.1;
    justify-content: center;
    padding-left: 20px;

}

.hero-left, .hero-right {
    flex: 1;
    display: flex;
    align-items: center; /* Center content */
    justify-content: center; /* Center content */
    padding: 20px; /* Add padding for smaller screens */
}


.hero-left h2{
    color: #090909;
    font-size: 26px;
    font-weight: 600;
}
/* .hero-left p{
    color: #171717;
    font-weight: 700;
    font-size: 5vw;
} */
.hero-left p {
    color: #171717;
    font-weight: 700;
    font-size: 5vw;
    margin-top: 20px; /* Add margin to create space between each <p> */
}

/* Move the group of <p> tags lower */
.hero-left > div {
    margin-top: 50px; /* Push the entire group down */
}
/* .hero-hand-icon{
    display: flex;
    align-items: center;
    gap: 20px;
} */
/* .hero-hand-icon img{
    width: 105px;
} */
/* .hero-latest-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 310px;
    height: 70px;
    border-radius: 75px;
    margin-top: 30px;
    background: #ff4141;
    color: white;
    font-size: 22px;
    font-weight: 500;
} */

/* .hero-latest-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 80%; 
    max-width: 310px; 
    height: 70px;
    border-radius: 75px;
    margin-top: 30px;
    background: #ff4141;
    color: white;
    font-size: 22px;
    font-weight: 500;
} */

/* .hero-right{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.hero-right img {
    width: 100%; /* Responsive image */
    max-width: 500px; /* Limit size on larger screens */
    height: auto; /* Maintain aspect ratio */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    .hero {
        flex-direction: column; /* Stack items */
    }

    .hero-left {
        padding-left: 0; /* Remove left padding on smaller screens */
        text-align: center; /* Center text */
    }

    .hero-left h2, .hero-left p {
        font-size: 20px; /* Adjust font size for mobile */
    }

    /* .hero-latest-btn {
        width: 90%; 
        font-size: 18px; 
    } */
}

@media (max-width: 480px) {
    .hero-left p {
        font-size: 15vw; /* Further reduce size for small screens */
    }
}