.footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 20px;
    width: 100%;
    background-color: #f8f8f8;
}

.footer-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Reduced gap between the logo and text */
}

.footer-logo img {
    width: 250px; /* Adjust the size of the logo */
    height: auto;
}

.footer-logo p {
    color: #383838;
    font-size: 1.5em; /* Adjust font size */
    font-weight: 700;
    margin: 0;
    text-align: center;
}
.about-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center; /* Ensure text alignment */
    padding: 20px;
    background-color: #f8f8f8; /* Optional: Match footer background */
    width: 100%; /* Full width */
    margin-top: 20px;
}

.about-title {
    font-size: 2em; /* Adjust the size for prominence */
    font-weight: 700; /* Make the font bold */
    color: #383838; /* Match the footer logo text color */
    margin: 0 0 10px 0; /* Add spacing below the title */
}

.about-text {
    font-size: 1em; /* Adjust font size for readability */
    color: #252525; /* Match the footer links text color */
    line-height: 1.5; /* Add some line spacing */
    max-width: 800px; /* Limit text width for better readability */
    margin: 0;
}

.footer-links {
    display: flex;
    list-style: none;
    gap: 30px;
    color: #252525;
    font-size: 20px;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
}

.footer-links li {
    cursor: pointer;
}

.footer-social-icon {
    display: flex;
    gap: 15px;
}

.footer-icons-container {
    padding: 10px;
    padding-bottom: 6px;
    background: #fbfbfb;
    border: 1px solid #ebebeb;
    border-radius: 5px;
}

.footer-icons-container img {
    width: 30px; /* Adjust the size of the icons */
    height: 30px;
    transition: transform 0.3s ease;
}

.footer-icons-container img:hover {
    transform: scale(1.1); /* Slight zoom effect on hover */
}

.footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-bottom: 20px;
    color: #1a1a1a;
    font-size: 1em;
    text-align: center;
}

.footer-copyright hr {
    width: 80%;
    border: none;
    border-radius: 10px;
    height: 3px;
    background: #c7c7c7;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .footer-logo p {
        font-size: 1.75em;
    }

    .footer-links {
        font-size: 1em;
        gap: 20px;
    }

    .footer-icons-container {
        padding: 8px;
    }

    .footer-icons-container img {
        width: 25px;
        height: 25px;
    }

    .footer-copyright {
        font-size: 0.9em;
    }
}

@media (max-width: 768px) {
    .footer-logo p {
        font-size: 1.5em;
    }

    .footer-links {
        font-size: 0.9em;
        gap: 15px;
    }

    .footer-icons-container {
        padding: 6px;
    }

    .footer-icons-container img {
        width: 20px;
        height: 20px;
    }

    .footer-copyright {
        font-size: 0.8em;
    }
}

@media (max-width: 480px) {
    .footer-logo p {
        font-size: 1.25em;
    }

    .footer-links {
        font-size: 0.8em;
        gap: 10px;
    }

    .footer-icons-container {
        padding: 5px;
    }

    .footer-icons-container img {
        width: 18px;
        height: 18px;
    }

    .footer-copyright {
        font-size: 0.75em;
    }
}

