.date-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4vw;
  background: linear-gradient(180deg, #fde1ff, #e1ffea22 60%);
  width: 100%;
  max-width: 400px; /* Increased maximum width */
  margin: 5vw auto;
  box-shadow: none;
  border: none;
}

.date-picker-container h3 {
  margin-bottom: 2vw;
  font-size: 2vw;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.date-picker-input,
.email-input {
  width: 100%; /* Full width within the container */
  padding: 1vw;
  margin: 1vw 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.25vw; /* Adjusted responsive font size */
  box-sizing: border-box;
}
.email-input {
  text-align: center;
}
.phone-input {
  width: 100%;
  padding: 1vw;
  margin: 1vw 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.25vw;
  box-sizing: border-box;
  text-align: center;
}
.phone-number-input {
  width: 100%; /* Full width within the container */
  padding: 10px; /* Adjust padding */
  margin: 10px 0; /* Spacing around the input */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px; /* Default font size for larger screens */
  box-sizing: border-box;
}

.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%; /* Full width within the container */
  max-width: 400px; /* Increased maximum width */
  height: 50px; /* Adjusted height */
  border-radius: 50px;
  margin-top: 30px;
  background: black;
  color: white;
  font-size: 1.25vw; /* Adjusted responsive font size */
  font-weight: 500;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #e63e3e;
}

@media (max-width: 320px) {
  .date-picker-container {
    padding: 2vw;
    max-width: 100%;
  }

  .date-picker-input,
  .email-input,
  .submit-btn {
    font-size: 3vw;
    padding: 2vw;
  }
}

@media (min-width: 768px) {
  .date-picker-container {
    padding: 2vw;
  }


  .date-picker-input,
  .email-input,
  .submit-btn {
    font-size: 1vw; /* Reduced font size for larger screens */
    padding: 1vw;
  }
  @media (max-width: 320px) {
    .phone-input {
      font-size: 3vw;
      padding: 2vw;
    }
  }
  @media (max-width: 480px) {
    .phone-number-input {
      font-size: 14px; /* Reduce font size for smartphones */
      padding: 8px; /* Adjust padding */
    }
  }
  
  @media (min-width: 768px) {
    .phone-input {
      font-size: 1vw;
      padding: 1vw;
    }
  }
}

.react-datepicker {
  width: 100%;
}

.react-datepicker__month-container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-datepicker__header {
  background-color: #ff4141;
  color: white;
}

.react-datepicker__day--selected {
  background-color: #e63e3e;
}
